import { Link } from "gatsby"
import React, { useState } from "react"
import logo from '../images/logo.svg'
import CtaButton from '../components/CtaButton'
import Navbar from '../components/Navbar'
import styled from "styled-components"


const Header = () => {
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    return (
        <div>

            <header>





                <Navbar>
                    <div className="container">
                        <div className="header-cont">

                            <div className="logo"> <Link to="/"><img src={logo} alt="logo" /></Link></div>

                            <nav >
                                <ul className="nav_list">
                                   

                                    <li><Link to="/how-it-works">How it works</Link></li>
                                    <li><Link to="/pricing">Pricing</Link></li>

                                {/*
                                    <li><a className="nav_list_pointer">Resources</a>
                                        <div className="sub-menu-single">
                                            <ul>
                                              <li><Link to="/ebooks">Ebooks</Link></li> 
                                                <li><a href="https://blog.inhero.com">Blog</a></li>
                                            </ul>
                                        </div>

                                    </li>  */}

                               

                                    <li><a href="https://app.inhero.com">Login</a></li>


                                </ul>


                            </nav>


                            <div className="btn-menu" id="myLinks">
                                <CtaButton />
                            </div>


                            <div className={`menu-toggle-icon ${isOpenMenu ? 'opened' : ''}`} onClick={() => setIsOpenMenu(!isOpenMenu)}></div>
                        </div>
                    </div>
                    <div className={`mobile-nav ${isOpenMenu ? 'open' : ''}`}>
                        <nav >
                            <ul className="mobile_nav_list">
                               {/* <li><Link to="/#product" onClick={() => setIsOpenMenu(!isOpenMenu)}>Product</Link></li> */}

                               <li><Link to="/how-it-works">How it works</Link></li>
                                <li><Link to="/pricing">Pricing</Link></li>

                                {/*
                                <li>
                                    <span onClick={() => setIsSubMenuOpen(!isSubMenuOpen)} className="nav_list_pointer">Resources</span>
                                    {isSubMenuOpen && (
                                        <div className="sub-menu-single">
                                            <ul>
                                                <li><Link to="/ebooks">Ebooks</Link></li> 
                                                <li><a href="https://blog.inhero.com">Blog</a></li>
                                            </ul>
                                        </div>
                                    )}

                                </li>  */ }

                                <li><Link to="/about-us">About Us</Link></li>

                                <li><a href="https://app.inhero.com">Login</a></li>


                            </ul>


                        </nav>


                        <div className="btn-menu" id="myLinks" onClick={() => setIsOpenMenu(!isOpenMenu)}>
                            <CtaButton />
                        </div>
                    </div>

                </Navbar>



            </header>
        </div>
    )
}






export default Header
