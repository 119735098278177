import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import logo from "../images/logo.svg";
import Popup from "./Popup";

// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };
//ReactPixel.init('641336672985474', options);
//ReactPixel.pageView(); //https://github.com/zsajjad/react-facebook-pixel

function Footer() {
  const [isOpen, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!isOpen);
  }

  useEffect(() => {
    import("react-facebook-pixel")
      .then((module) => module.default)
      .then((ReactPixel) => {
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };

        ReactPixel.init("641336672985474", options);
        ReactPixel.pageView();
      });
  }, []);

  return (
    <div className="footer-bg">
      <div className="container">
        <footer>
          <div className="footer-items">
            <div className="footer-items-col-3">
              <p>PRODUCT</p>
              <p>
                <Link to="/how-it-works">How it works</Link>
                
              </p>
              <p>
                <Link to="/pricing">Pricing</Link>
              </p>
              <p>
                <Link to="/#product">Reviews</Link>
              </p>


              
              <p>
                <Link to="#" onClick={handleClick}>Start trial</Link>
                {isOpen && <Popup isOpen={isOpen} setOpen={setOpen}/>}
              </p>
             
            </div>

            <div className="footer-items-col-4">
              <p>RESOURCES</p>
              <p>
                <Link to="/contact">Support</Link>
              </p>
             

              <p>
                <a href="http://blog.inhero.com" target="_blank">Blog</a>
              </p>



            </div>

            <div className="footer-items-col-1">
              <p>COMPANY</p>
              <p>
                <Link to="/about-us">About us</Link>
              </p>
              <p>
                <Link to="/contact">Contact us</Link>
              </p>
              <p>
                <Link to="/terms">Privacy & terms</Link>
              </p>
            </div>

            <div className="footer-items-col-5">
              <p></p>
              <p>We're based in Manchester, UK</p>
            </div>
          </div>

          <div className="footer-bottom">
            <img src={logo} alt="" />
            <p>© InHero 2021</p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Footer;
