import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import "../css/style.scss"
import "../css/normalize.css"
import "../css/responsive.scss"
import { Helmet } from "react-helmet"
import ogicon from "../images/og_icon.png"




const Layout = (props) => {
    return(
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <meta property="og:type" content="website"/>
                <meta property="og:image" content={ogicon}/>
                <meta name="theme-color" content="#6F7B84" />

                
            </Helmet>

            <Header />
            {props.children}
            <Footer />
        </div>
    )
}

export default Layout