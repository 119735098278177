import React, { useState } from "react"
import { Formik, Form, Field } from "formik"
import ReactModal from "react-modal"
import { Link } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import * as Yup from "yup"
import Popup from "./Popup"

const TrialSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid Email").required("Required"),
  mobile: Yup.string().matches(/^\d+$/, "Digits Only").required("Required"),
})

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px 0 0 10px",
    maxWidth: "432px",
    height: "628px",
    maxHeight: "100%",
    padding: "18px 18px 41px 30px",
    fontWeight: "500",
    zIndex: "500",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.58)",
    zIndex: "500",
  },
}

const formStyle = {
  display: "flex",
  flexDirection: "column",
}

function CtaButton({ classes }) {
  const [isOpen, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!isOpen)
    console.log(classes)
  }

  return (
    <div>
      <div id="main">
        <Link to="#" onClick={handleClick}>
          <button className={`btn btn-main ${classes === null ? "" : classes}`}>
            START FREE
          </button>
        </Link>
        {isOpen && <Popup isOpen={isOpen} setOpen={setOpen} />}
      </div>

      {/* <ReactModal
        isOpen={isOpen}
        onRequestClose={handleClick}
        contentLabel="Modal Here"
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
        <div className="popClose" onClick={handleClick}></div>
        <Formik
          initialValues={{
            companyName: "",
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
          }}
          validationSchema={TrialSchema}
          onSubmit={(values, actions) => {
            let formData = new FormData()
            for (let key in values) {
              formData.append(key, values[key])
            }
            fetch("https://getform.io/f/01863296-0f30-4ef8-a416-7bc932896a4a", {
              method: "POST",
              body: formData,
            })
              .then(result => {
                // handleClick()
                // console.log(result.url)
                window.location.href = result.url
              })
              .catch(error => {
                console.log(error)
              })
          }}
        >
          {({ errors, touched }) => (
            <Form style={formStyle} className="trialForm">
              <div className="popTitle">Let’s set you up!</div>
              <div className="service">
                <div className="p-tick"></div>
                <span style={{ marginRight: "14px" }}>30 day free trial</span>
                <div className="p-tick"></div>
                <span>No Card Required</span>
              </div>
              <div className="label">Company name</div>
              <Field name="companyName" />
              {errors.companyName && touched.companyName ? (
                <div className="formErrors">{errors.companyName}</div>
              ) : null}

              <div className="nameWrap">
                <div>
                  <div className="label">First Name</div>
                  <Field name="firstName" style={{ width: "174px" }} />
                  {errors.firstName && touched.firstName ? (
                    <div className="formErrors">{errors.firstName}</div>
                  ) : null}
                </div>

                <div style={{ marginLeft: "11px" }}>
                  <div className="label">Last Name</div>
                  <Field name="lastName" style={{ width: "125px" }} />
                  {errors.lastName && touched.lastName ? (
                    <div className="formErrors">{errors.lastName}</div>
                  ) : null}
                </div>
              </div>

              <div className="label">Your email</div>
              <Field name="email" type="email" />
              {errors.email && touched.email ? (
                <div className="formErrors">{errors.email}</div>
              ) : null}

              <div className="label">Your mobile</div>
              <Field name="mobile" />
              {errors.mobile && touched.mobile ? (
                <div className="formErrors">{errors.mobile}</div>
              ) : null}

              <div className="terms">
                By submitted this form, you’re agreeing to accept{" "}
                <a href="/terms" target="_blank" style={{ textDecoration: "underline" }}>
                  InHero terms of service
                </a>
              </div>
              <button type="submit" className="btn btn-main btn-trial">
                Request Trial
              </button>
            </Form>
          )}
        </Formik>
      </ReactModal> */}
    </div>
  )
}

export default CtaButton
